.my-badge {
    border-collapse: separate;
    border-spacing: 0;
    caption-side: top;
    cursor: auto;
    direction: ltr;
    empty-cells: show;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    font-stretch: normal;
    hyphens: none;
    letter-spacing: normal;
    list-style: disc outside none;
    tab-size: 8;
    text-align: left;
    text-align-last: auto;
    text-indent: 0;
    text-shadow: none;
    text-transform: none;
    visibility: visible;
    white-space: normal;
    widows: 2;
    word-spacing: normal;
    box-sizing: border-box;
    letter-spacing: var(--font-body-s-letter-spacing-svMjV2, normal);
    font-weight: 400;
    font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
    display: inline-block;
    border-radius: var(--border-badge-radius-25ntG7, 16px);
    word-wrap: break-word;
    pointer-events: auto;
    padding: 0 var(--space-xs-2P4jwl, 8px);
}