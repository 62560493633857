body{
    margin: 0px !important;
}
.header{
    padding: 10px;
    padding-bottom: 0px !important;
}
.no-padding{
    padding: 0px !important;
}
.device-badge{
    margin-right: 3px;
    margin-bottom: 1px;
}
